import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export function slide() {
    const options1 = {
        type: "loop", // ループさせる
        arrows: false, // 矢印ボタンを非表示
        pagination: false, // ページネーションを非表示
        drag: "free", // フリードラッグモード
        gap: 30, // スライド間の余白
        perPage: 5, // 表示するスライドの枚数
        breakpoints: {
            768: {
                perPage: 2, // 画面幅500px未満で表示枚数1枚
                gap: 30, // 画面幅500px未満でスライド間の余白0
            },
        },
        autoScroll: {
            speed: 0.3, // スクロール速度
            pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        },
    };

    const options2 = {
        direction: "rtl", // スライドの方向を左から右
        type: "loop", // ループさせる
        arrows: false, // 矢印ボタンを非表示
        pagination: false, // ページネーションを非表示
        drag: "free", // フリードラッグモード
        gap: 30, // スライド間の余白
        perPage: 5, // 表示するスライドの枚数
        breakpoints: {
            768: {
                perPage: 2, // 画面幅500px未満で表示枚数1枚
                gap: 30, // 画面幅500px未満でスライド間の余白0
            },
        },
        autoScroll: {
            speed: 0.3, // スクロール速度
            pauseOnHover: false, // カーソルが乗ってもスクロールを停止させない
        },
    };

    const splide1 = new Splide(".splide1", options1);
    splide1.mount({ AutoScroll }); // AutoScroll 拡張機能を手動で追加

    const splide2 = new Splide(".splide2", options2);
    splide2.mount({ AutoScroll }); // AutoScroll 拡張機能を手動で追加
}